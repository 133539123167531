.more {
    composes: input from '../../Inputs/Inputs.module.css';

    color: transparent;
    position: relative;
    width: 64px;
    height: 64px;
    align-self: center;
    cursor: pointer;
    transition: all .3s ease;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.more:hover, .more:active {
    background: rgba(255,255,255,.1);
}

.more::before {
    content: '';
    position: absolute;
    width: 32px;
    height: 2px;
    background: #fff;
    left: 50%;
    top: 50%;
    margin-top: -1px;
    margin-left: -16px;
    box-shadow: 0 -9px 0 0 #fff, 0 9px 0 0 #fff;
}

.options {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 8px;
    left: 8px;
    background: var(--background);
    min-width: 170px;
    box-shadow: 0 1px 20px rgba(0,0,0,.2), 0 1px 5px rgba(0,0,0,.2);
    border-radius: 3px;
    transform-origin: bottom left;
    animation: options ease-out .1s;
    overflow: hidden;
}

@keyframes options {
    from { opacity: 0; transform: scale(.8); }
}

.option {
    composes: input from '../../Inputs/Inputs.module.css';

    padding: 16px 22px;
    text-align: left;
    cursor: pointer;
    transition: background-color .3s ease;
    color: var(--color);
}

.option + .option {
    border-top: 1px solid var(--border-color);
}

.option:hover {
    background: var(--background-light);
}

@media(max-width: 768px) {
    .more {
        width: 48px;
        height: 48px;
    }

    .more::before {
        width: 24px;
        margin-left: -12px;
        box-shadow: 0 -7px 0 0 #fff, 0 7px 0 0 #fff;
    }

    .options {
        bottom: auto;
        left: auto;
        top: 8px;
        right: 8px;
        transform-origin: top right;
    }
}
