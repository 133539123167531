.overlay {
    position: fixed;
    top: 0; bottom: 0;
    left: 0; right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 15;
    -webkit-backdrop-filter: blur(2px);
}

.overlay:after {
    content: '';
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    background: rgba(0,0,0,.2);
    animation: overlay .5s ease-out;
    will-change: transform;
    z-index: -1;
}

@keyframes overlay {
    from { opacity: 0; }
}
