.button {
    composes: clickable from '../../Inputs/Inputs.module.css';

    border-radius: 16px;
    height: 32px;
    line-height: 32px;
    padding: 0 15px;
    margin: 0 6px 6px 0;
    display: inline-block;

    --icon-color-secondary: var(--input-background-clickable);
}

.button:focus {
    --icon-color-secondary: var(--focus);
}

.active {
    composes: button;

    background-color: var(--input-border-color);
}

.icon {
    vertical-align: -5px;
    margin: 0 4px 0 -8px;
}
