.tooltip {
    composes: inner from '../tooltip.module.css';
}

.title {
    font-weight: bold;
    margin-bottom: 8px;
}

.facts {
    margin-top: 12px;
}

.actionButton {
    background: #3023ae;
    color: #fff;
    border: none;
    font: inherit;
    font-weight: normal;
    text-align: center;
    width: 100%;
    padding: 8px;
    text-transform: capitalize;
    margin-top: 16px;
    border-radius: 2px;
}

.actionHint {
    margin-top: 12px;
    padding-top: 8px;
    color: var(--color-muted);
    font-style: italic;
    border-top: 1px solid var(--border-color);
}
