.dialog {
    background: var(--background);
    box-shadow: 0 1px 40px rgba(0,0,0,.2);
    max-width: calc(100vw - 32px);
    max-height: calc(100vh - 32px);
    display: flex;
    flex-direction: column;
    overflow: auto;
    overscroll-behavior: contain;
    padding: 16px;
    width: 400px;
    box-sizing: border-box;
    animation: dialog .2s ease;
}

@keyframes dialog {
    from { opacity: 0; transform: scale(1.05); }
}

.header {
    margin: -16px -16px 16px;
    font-size: 16px;
    padding: 16px;
    background: var(--background-light);
    border-bottom: 1px solid var(--border-color);
    height: 48px;
    box-sizing: border-box;
    line-height: 16px;
}

.closeButton {
    composes: input from '../Inputs.module.css';

    float: right;
    padding: 0;
    width: 48px;
    height: 48px;
    margin: -16px -16px -16px 0;
    cursor: pointer;
    transition: all .3s ease;

    color: var(--icon-color);
}

.closeButton:hover {
    color: var(--color);
}

.closeButton:focus {
    outline: none;
    color: var(--focus);
}
