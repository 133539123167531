.section {
    position: relative;
    flex: 1;
}

.header {
    background: var(--background-light);
    border-bottom: 1px solid var(--border-color);
    line-height: 64px;
    padding: 0 16px;
    font-size: 16px;
}

.section + .section .header {
    border-top: 1px solid var(--border-color);
}

.section + .section .anchor {
    margin-top: 1px;
}

.content {
    padding: 16px;
    overflow-x: auto;
}

.anchor {
    width: 0; height: 0;
    visibility: hidden;
    position: absolute;
}

.action {
    composes: clickable from '../../Inputs/Inputs.module.css';

    float: right;
    font-size: 1rem;
    margin: 12px 0 12px 16px;
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    border-radius: 3px;
    cursor: pointer;
}

@media(max-width: 768px) {
    .anchor {
        margin-top: -48px;
    }

    .section + .section .anchor {
        margin-top: -47px;
    }
}
