.selectionPopup {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 6;
    color: white;
}

.selectionPopupLeft,
.selectionPopupInner {
    background: rgba(0, 0, 0, 0.8);
}

.selectionPopupLeft {
    height: 100%;
    width: 12px;
}

.selectionPopupInner {
    position: absolute;
    left: 0;
    margin-left: 12px;
    padding: 7px 7px 7px 11px;
    min-height: calc(100% + 76px);
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
}

.specializationIcons {
    display: flex;
}

.specializationIcons > div + div {
    margin-left: 10px;
}

.selectionPopupBorderTop,
.selectionPopupBorderBottom {
    position: absolute;
    left: 1px;
    width: 15px;
    height: 36px;
}

.selectionPopupBorderTop polygon,
.selectionPopupBorderBottom polygon {
    fill: white;
}

.selectionPopupBorderTop {
    top: -32px;
}

.selectionPopupBorderBottom {
    bottom: -32px;
}
