.select {
    composes: clickable from '../Inputs.module.css';

    position: relative;
    display: inline-block;

    min-width: 150px;

    border-radius: 3px;
    padding: 10px 26px 10px 10px;
    margin: 0;
}

.select::after {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -2px;
    border-style: solid;
    border-color: var(--icon-color) transparent transparent;
    border-width: 6px 4px 0;
    pointer-events: none;
}

/*.select:focus::after {*/
    /*border-top-color: var(--input-color-focus);*/
/*}*/

.disabled {
    composes: select;

    cursor: default;
    color: #444;
}

.disabled:after {
    border-top-color: #e4e4e4;
}

.dropdown {
    position: fixed;
    top: 20px;
    left: 20px; right: 20px;
    max-height: calc(100% - 40px);
    box-shadow: 0 1px 20px rgba(0,0,0,.2), 0 1px 5px rgba(0,0,0,.2);
    z-index: 44;
    background: var(--background);
    border-radius: 3px;
    margin: 8px 0;

    overflow: hidden;
    display: flex;
    flex-direction: column;

    animation: dropdown .2s ease-out;
}

.options {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
    flex: 1;
    flex-basis: auto;
}

@keyframes dropdown {
    from { opacity: 0; }
}

.dropdown .option {
    padding: 16px;
    transition: background .1s ease;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.dropdown .option:hover {
    background: var(--background-light);
}

.option + .option {
    border-top: 1px solid var(--border-color);
}

.disabledOption {
    composes: option;
}

.dropdown .disabledOption {
    cursor: default;
    color: var(--color-muted);
}

.dropdown .disabledOption:hover {
    background: var(--background);
}

.dropdown .activeOption {
    color: var(--focus);
}

.dropdown .activeOption:before {
    content: '';
    display: block;
    position: absolute;
    left: 7px;
    top: calc(50% - 2px);
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: var(--focus);
}

.dropdown .activeOption.highlightOption::before {
    background: var(--input-color-focus);
}

.dropdown .highlightOption {
    background: var(--input-background-clickable-focus);
    color: var(--input-color-focus)
}

.group {
    position: relative;
}

.groupLabel {
    padding: 16px;
    background: #f9f9f9;
    font-weight: bold;
    position: sticky;
    top: 0;
}

:global(.js-select-disable-scroll) body {
    overflow: hidden;
}

.filter {
    background: var(--background);
    position: sticky;
    top: 0;
    z-index: 3;
    padding: 8px;
    box-shadow: 0 0 3px rgba(0,0,0,.2);
}

.filterInput {
    composes: editable from '../Inputs.module.css';

    padding: 8px;
    width: 100%;
    box-sizing: border-box;
}
