.panel {
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

:global(.js-select-disable-scroll) .panel {
    overflow: hidden;
}



@media(max-width: 768px) {
    .panel {
        margin-top: 48px;
    }
}
