html, body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    /*font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;*/
    font-size: 14px;
    background: var(--background);
    color: var(--color);
    height: 100%;
    overflow: hidden;
    position: fixed;
    left: 0; right: 0;
    top: 0; bottom: 0;
}

:focus:not(:focus-visible) {
    outline: none;
}

::-moz-focus-inner {
    border: 0;
}

:global(#root) {
    height: 100%;
}

::selection {
    background: var(--focus);
    color: var(--input-color-focus);
}

a {
    color: var(--focus);
}

.frame {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.loading {
    position: fixed;
    top: 32px;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;
    background: var(--background);
    color: var(--color);
    padding: 8px 16px;
    box-shadow: 0 1px 2px rgba(0,0,0,.2);
    border-radius: 2px;
    opacity: 0;
    transition: opacity .5s ease-in;
    pointer-events: none;
}

.loadingVisible {
    composes: loading;

    opacity: 1;
}

.dialogButton {
    composes: input from '../Inputs/Inputs.module.css';

    background: #3023ae;
    color: white;
    padding: 8px 16px;
    display: block;
    margin-top: 8px;
    width: 100%;
    cursor: pointer;
    transition: background-color .3s ease;
}

.dialogButton:hover {
    background-color: #c86dd7;
}

@media(max-width: 768px) {
    .frame {
        height: auto;
        display: block;
    }

    html, body, :global(#root) {
        height: auto;
        overflow: initial;
        position: static;
    }

    .loading {
        top: 24px;
    }
}
