.container {
    flex: 1;

    display: flex;
    flex-direction: row;

    overflow: hidden;
}

.stats {
    width: 25%;
    min-width: 300px;
    max-width: 400px;
    border-left: 1px solid var(--border-color);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
}

:global(.js-select-disable-scroll) .stats {
    overflow: hidden;
}

.footer {
    color: var(--color-muted);
    font-size: 11px;
    margin: 24px 16px;
    text-align: center;
}

.footer a {
    color: inherit;
    text-decoration: none;
}

.footer a:hover, .footer a:focus {
    text-decoration: underline;
}

@media(max-width: 768px) {
    .container {
        display: initial;
    }

    .stats {
        width: 100%;
        max-width: none;
        min-width: 0;
        border-left: 0;
        border-top: 1px solid var(--border-color);
    }
}
