.component {
    display: flex;
    margin-bottom: 4px;
    margin-right: 16px;
}

.initiative {
    line-height: 8px;
    align-self: center;
    margin-left: 4px;
    display: flex;
    flex: 1;
    justify-content: space-around;
}

.initiative > span {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    box-shadow: 0 0 3px red;
    transform: rotate(45deg);
    background: #fff;
}

