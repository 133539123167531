.account {}

.accountHeader {
    background: var(--background-light);
    font-weight: bold;
    padding: 4px 8px;
    margin-bottom: 4px;
}

.character {
    display: flex;
    align-items: center;
    padding: 2px 8px;
    clear: both;
}

.characterInfo {
    flex: 1;
}

.characterName {}

.characterStats {
    color: #999;
    font-size: 12px;
}

.characterLevel {
    font-weight: bold;
}

.characterActions {

}

.loading {
    padding: 4px 8px;
    line-height: 1.5;
}

.characterAction {
    composes: input from '../Inputs/Inputs.module.css';

    width: 32px;
    height: 32px;
    background: no-repeat center/contain;
    filter: grayscale(1) contrast(0.7) brightness(1.3);
    transition: filter .3s ease;
    cursor: pointer;
    overflow: hidden;
    padding: 0;
    text-indent: 32px;
}

.characterAction:hover {
    filter: none;
}

.characterActionPve { composes: characterAction; background-image: url(./pve.png); }
.characterActionPvp { composes: characterAction; background-image: url(./pvp.png); }
.characterActionWvw { composes: characterAction; background-image: url(./wvw.png); }

.addButton {
    composes: clickable from '../Inputs/Inputs.module.css';

    text-align: left;
    width: 100%;
    padding: 4px 8px;
    margin-top: 8px;
    transition: background-color .3s ease;
}

.addButtonIcon {
    font-weight: bold;
    color: var(--icon-color);
    width: 12px;
    height: 12px;
    font-size: 16px;
}

.removeButton {
    composes: input from '../Inputs/Inputs.module.css';

    float: right;
    font-weight: normal;
    color: #666;
    cursor: pointer;
}

.removeButton:hover {
    text-decoration: underline;
}

.accountError {
    margin: 8px 8px 12px;
}

.accountErrorKey {
    font-size: 8px;
    color: #999;
    margin-top: 4px;
}

.search {
    composes: editable from '../Inputs/Inputs.module.css';

    padding: 8px 8px;
    margin-bottom: 8px;
    width: 100%;
    box-sizing: border-box;
    -webkit-appearance: textfield;
}
