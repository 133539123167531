.description {
    margin: 0 0 8px;
}

.inputGroup {
    margin-top: 8px;
    display: flex;
}

.input {
    composes: editable from '../Inputs/Inputs.module.css';

    flex: 1;
    padding: 8px 12px;
    margin-right: 8px;
}

.button {
    composes: clickable from '../Inputs/Inputs.module.css';

    width: 42px;
    height: 42px;
    text-align: center;
    position: relative;
    cursor: pointer;
    transition: background-color .3s ease;
}

.button::after {
    content: '';
    display: block;
    border-style: solid;
    border-color: transparent var(--icon-color);
    border-width: 8px 0 8px 12px;
    position: absolute;
    top: calc((42px / 2) - (16px / 2));
    left: calc((42px / 2) - (12px / 2));
}

.loader {
    width: 42px;
    height: 42px;
    color: var(--focus);

    position: relative;
}

.loader::after {
    content: '';

    position: absolute;
    top: 8px; bottom: 8px;
    left: 8px; right: 8px;

    border: 3px solid;
    border-color: currentColor transparent;
    border-radius: 100px;

    animation: loader 1.5s infinite linear;
}

@keyframes loader {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.error {
    color: #ef5350;
    margin-top: 8px;
}
