.specializationIcon {
    height: 32px;
    margin: 2px 0;
    padding: 2px 6px 2px 36px;
    line-height: 32px;
    background-size: 32px;
    background-position: left center;
    background-repeat: no-repeat;
    filter: brightness(0.5);
    transition: filter 200ms;
    cursor: pointer;
}

.specializationIconSelected {
    composes: specializationIcon;
}
.specializationIconSelected,
.specializationIcon:hover {
    filter: unset;
}
