.select {
    display: inline-block;
    position: relative;
    cursor: pointer;
    border: 1px solid #000;
}

.select:not(:last-child) {
    border-right: none;
}

.select:focus {
    outline: none;
    border: 1px solid var(--input-border-color-focus);
}

.select:focus + .select {
    border-left: none;
}

.select:after {
    content: '';
    position: absolute;
    top: -9px;
    left: 50%;
    margin-left: -4px;
    border-style: solid;
    border-width: 0 4px 6px;
    border-color: transparent transparent var(--icon-color) transparent;
}

.select:focus::after {
    border-bottom-color: var(--input-border-color-focus);
}

.select:focus::before {
    content: '';
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    z-index: 1;
    background: var(--focus);
    opacity: 0.25;
    pointer-events: none;
}

.option {
    margin: -16px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 8px;
    align-self: flex-start;
    flex-shrink: 0;
}

.disabledIcon {
    composes: icon;

    filter: grayscale(1);
}

.name {
    margin-top: -2px;
}
