.majorIcon {
    composes: icon from './traitIcon.module.css';
    display: block;
    margin: 0 -5px;
    filter: brightness(0.5);
    transition: filter 300ms;
}

.majorIcon:hover {
    filter: brightness(0.9) sepia(10%) contrast(80%);
    transition: filter 150ms;
}

.majorIconSelected {
    composes: majorIcon;
}

.majorIconSelected,
.majorIconSelected:hover {
    filter: unset;
    transition: unset;
}
