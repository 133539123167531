.input {
    composes: editable from '../../Inputs/Inputs.module.css';

    padding: 12px 48px 12px 12px;
    width: 100%;
    box-sizing: border-box;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text {
    margin: 0 0 16px 0;
}

.inputWrapper {
    position: relative;
}

.copyButton {
    composes: input from '../../Inputs/Inputs.module.css';

    position: absolute;
    right: 3px;
    top: 3px;
    bottom: 3px;
    background: var(--input-background-editable);
    cursor: pointer;
    width: 40px;
    padding: 0;
    color: var(--icon-color);
}

.copyButton:hover {
    background: var(--input-background-clickable);
}

.copyButton:focus {
    outline: none;
    background: var(--input-background-clickable-focus);
    color: var(--input-color-focus);
}

.copyButtonIcon {
    vertical-align: middle;
    width: 22px;
    height: 22px;
}
