.setting {
    display: flex;
    align-items: center;
}

.setting + .setting {
    margin-top: 16px;
}

.setting label {
    flex: 1;
}
