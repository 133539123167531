.tooltip {
    position: fixed;
    top: 0; left: 0;

    z-index: 100;
    pointer-events: none;
    will-change: transform;
}

.inner {
    background: var(--background);
    padding: 12px 16px;
    margin: 8px;
    max-width: 500px;
    box-shadow: 0 1px 40px rgba(0,0,0,.2);
}

.touch .inner {
    margin: 0 auto -100vh;
    box-shadow: none;
    padding-bottom: calc(100vh + 12px);
}

.touch {
    position: fixed;
    left: 0; right: 0;
    bottom: 0;
    box-shadow: 0 32px 32px 32px rgba(0,0,0,.3);
    background: var(--background);
    padding-top: 8px;

    z-index: 100;
    will-change: transform;
    transform: translateY(100%) translateY(-148px);

    animation: touch .2s ease-out;
}

@keyframes touch {
      0% { transform: translateY(100%) translateY(-0px); }
}

:global(.color-format--abilitytype) {
    color: #f57f17;
}

:global(.color-format--reminder) {
    color: #888;
}
