.component {
    display: flex;
    margin-bottom: 4px;
    width: 326px;
}

.illusions {
    line-height: 16px;
    align-self: center;
}

.illusions > span {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background: #ff80fb;
    border: 2px solid #a252f4;
    margin-right: 8px;
}

.eliteSlot {
    display: inline;
    margin-left: 4px;
}
