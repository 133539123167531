.input {
    font: inherit;
    border: none;
    background: none;
    border-radius: 0;
    margin: 0;
}

.clickable {
    composes: input;

    color: var(--input-color);
    border: 1px solid var(--input-border-color);
    background: var(--input-background-clickable);

    cursor: pointer;
}

.clickable:hover {
    border-color: var(--input-border-color-hover);
}

.clickable:focus {
    --icon-color: var(--input-color-focus);

    outline: none;
    border-color: var(--input-border-color-focus);
    color: var(--input-color-focus);
    background-color: var(--input-background-clickable-focus);
}

.editable {
    composes: input;

    color: var(--input-color);
    border: 1px solid var(--input-border-color);
    background: var(--input-background-editable);

    cursor: text;
}

.editable:hover {
    border-color: var(--input-border-color-hover);
}

.editable:focus {
    outline: none;
    border-color: var(--input-border-color-focus);
    background-color: var(--input-background-editable-focus);
}
