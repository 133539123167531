.sidebar {
    width: 64px;
    box-shadow: 0 0 3px rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    user-select: none;
    background: linear-gradient(var(--brand1), var(--brand2));
    z-index: 11;
}

.logo {
    display: inline-block;
    width: 64px;
    height: 64px;
    text-align: center;
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.logo img {
    margin: 10px;
}

.sections {
    position: relative;
    flex: 1;
}

.marker {
    position: absolute;
    left: 0;
    top: 0;
    height: 64px;
    width: 4px;
    background-color: #fff;
    z-index: 1;

    will-change: transform;
    transition: transform .3s cubic-bezier(0.5, 0.01, 0.2, 1);
}

.button {
    font: inherit;
    width: 64px;
    height: 64px;
    background: transparent;
    border: none;
    border-radius: 0;
    outline: none;
    padding: 0;
    text-align: center;
    line-height: 64px;
    cursor: pointer;
    transition: background .3s ease;
}

.button:hover {
    background: rgba(255,255,255,.1);
}

.button img {
    vertical-align: middle;
}

@media(max-width: 768px) {
    .sidebar {
        flex-direction: row;
        width: 100%;
        height: 48px;
        background: var(--brand1);

        position: fixed;
        top: 0;
    }

    .button {
        width: 48px;
        height: 48px;
        line-height: 48px;
    }

    .button img {
        height: 38px;
        width: 38px;
    }

    .marker {
        height: 4px;
        width: 48px;
        top: calc(48px - 3px);
    }

    .logo {
        width: 48px;
        height: 48px;
        border-bottom: none;
    }

    .logo img {
        margin: 8px;
        width: 32px;
        height: 32px;
    }
}
