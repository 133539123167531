.fact + .fact {
    margin-top: 4px;
}

.traitedFact {
    composes: fact;

    color: var(--focus);
}

.fact img {
    vertical-align: -1px;
    margin-right: 4px;
}

.description {
    margin-left: 20px;
    color: var(--color-muted);
}
