.backButton {
    composes: input from '../Inputs/Inputs.module.css';

    float: left;
    padding: 0;
    height: 48px;
    width: 48px;
    margin: -16px 0 -16px -16px;
    cursor: pointer;
    transition: all .3s ease;

    color: var(--icon-color);
}

.backButton:hover {
    color: var(--color);
}

.backButton:focus {
    color: var(--focus);
    outline: none;
}
